import React from "react";
import { NavItem, NavLink, Badge, Collapse, DropdownItem } from "shards-react";
import { API_URL } from "../../../../config";
import { strings } from "../../../../localization";
import { Store, Dispatcher, Constants } from "../../../../flux";

export default class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      notifications: [],
      isLoading: true,
      notificationCount: Store.getNotificationCount()
    };

    this.toggleNotifications = this.toggleNotifications.bind(this);
    this.intervalId = null;
  }

  toggleNotifications() {
    if (!this.state.visible) {
      Dispatcher.dispatch({
        actionType: Constants.RESET_NOTIFICATION
      })
      this.getNotifications();
      this.intervalId = setInterval(this.getNotifications, 5000);
      this.setState({
        isLoading: true
      })
    } else {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    }
    this.setState({
      visible: !this.state.visible
    });
  }

  // This function is used to call restaurant kot get api
  getNotifications = async () => {
    this.setState({
      isLoading: true
    })
    let statusCode = 0;
    const apiUrl = `${API_URL}get-notification/restaurant`
    fetch(apiUrl, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    }).then((res) => {
      statusCode = res.status;
      return res.json();
    }).then((res) => {
      console.log("🚀 response of notifications api:", res)
      if (statusCode === 200) {
        if (res && res.result && res.result.length > 0) {
          const result = res.result;
          this.setState({
            notifications: result
          })
        } else {
          this.setState({
            notifications: []
          })
        }
      }
      this.setState({
        isLoading: false
      })
    }).catch((err) => {
      console.log('catch block error', err);
      this.setState({
        isLoading: false
      })
    })
  }

  onNotificationChange = () => {
    this.setState({
      notificationCount: Store.getNotificationCount()
    })
  };

  componentDidMount() {
    this.getNotifications()
    Store.addChangeListener(this.onNotificationChange)
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onNotificationChange)
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  getTimeAgoStringFromDate = (inputDate) => {
    const now = new Date();
    const date = new Date(inputDate); // Ensure input is a valid date
    const diff = Math.floor((now - date) / 1000); // Difference in seconds

    const timeUnits = [
      { seconds: 31536000, singular: 'year_ago', plural: 'years_ago' },
      { seconds: 2592000, singular: 'month_ago', plural: 'months_ago' },
      { seconds: 604800, singular: 'week_ago', plural: 'weeks_ago' },
      { seconds: 86400, singular: 'day_ago', plural: 'days_ago' },
      { seconds: 3600, singular: 'hour_ago', plural: 'hours_ago' },
      { seconds: 60, singular: 'minute_ago', plural: 'minutes_ago' },
      { seconds: 1, singular: 'second_ago', plural: 'seconds_ago' },
    ];

    for (const unit of timeUnits) {
      if (diff >= unit.seconds) {
        const value = Math.floor(diff / unit.seconds);
        const stringKey = value === 1 ? unit.singular : unit.plural;
        return `${value} ${strings[stringKey]}`;
      }
    }

    return `${Math.floor(diff / 31536000)} ${strings.years_ago}`;
  };

  render() {
    return (
      <NavItem className="border-right dropdown notifications">
        <NavLink
          className="nav-link-icon text-center"
          style={{ 'cursor': 'pointer' }}
          onClick={this.toggleNotifications}
        >
          <div className="nav-link-icon__wrapper">
            <i className="material-icons" style={{ color: 'black' }}>&#xE7F4;</i>
            {
              this.state.notificationCount && this.state.notificationCount > 0 &&
              <Badge pill theme="danger">
                {this.state.notificationCount}
              </Badge>
            }
          </div>
        </NavLink>
        <Collapse
          open={this.state.visible}
          className="dropdown-menu dropdown-menu-small"
          style={{ overflow: 'hidden', maxHeight: '50vh' }}
        >
          <div style={{ height: 'auto' }}>
            <div style={{ overflow: 'auto', maxHeight: '40vh' }}>
              {
                this.state.notifications.length > 0 && this.state.notifications.map((item) => {
                  const data = item.data;
                  const notification = data.notification ? data.notification : "";
                  return (
                    <DropdownItem>
                      <div className="notification__content">
                        <span className="notification__category">{notification.title ? notification.title : ""}</span>
                        <p>
                          {notification.body ? notification.body : ""}
                        </p>
                      </div>
                      <p style={{ marginLeft: 'auto' }}>{data.created_date ? this.getTimeAgoStringFromDate(data.created_date) : ""}</p>
                    </DropdownItem>
                  )
                })
              }
            </div>
            {/* <div>
              <DropdownItem className="notification__all text-center">
                View all Notifications
              </DropdownItem>
            </div> */}
          </div>
        </Collapse>
      </NavItem>
    );
  }
}
