const isFirebaseEnabled = process.env.REACT_APP_IS_FIREBASE_ENABLED;

export const getFCMToken = async () => {
    try {
        let fcmToken = "";
        if (isFirebaseEnabled === "true") {
            console.log('in if condition of isFirebaseEnabled')
            const firebase = await import('firebase/messaging');
            const messaging = ((await import('../firebase')).default)

            fcmToken = await firebase.getToken(messaging, {
                vapidKey: 'BLp8XL4qume3MzVPr5n1UnMwvjxChZCLZ2xRpGx47HUZylmjiylVJ4zihfMVnDI8p-Dl1a-R1GCN_ybGwd3DEwc',
            });
        }
        return fcmToken;
    } catch (error) {
        console.log('error in getFCMToken', error)
    }
}