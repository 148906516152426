import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";

import { ToastContainer } from "react-toastify";
import { getFCMToken } from "./utils/firebaseUtils";

export default function App() {
  const requestPermission = async () => {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();
    console.log('permission', permission)
    if (permission === "granted") {
      const token = await getFCMToken();

      console.log("Token generated : ", token);
    } else if (permission === "denied") {
      //notifications are blocked
      console.log('notification permission denied')
      // alert("You denied for the notification");
    }
  }

  // TOOD: NOTIFICATION CODE
  useEffect(() => {
    requestPermission();
  }, []);
  return (
    <Router>
      <div>
        {routes.map((route, index) => {
          return (
            <>
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={withTracker(props => {
                  return (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  );
                })}
              />
              {/* <Route component={Errors} /> */}
              {/* <Redirect to="/404" /> */}
              {/* <Route path="*" component={NotFoundRoute} /> */}
              <ToastContainer />
            </>
          );
        })}
      </div>
    </Router>
  )
};
