import React, { Component } from "react";
import {
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";
// import axios from "axios";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import Logo from "./../../images/ebol_logo.png";
import PasswordField from "./Password";
import {
  Language,
  API_URL,
  HEADER_ORIGIN_KEY,
  HEADER_ORIGIN,
  updateNumber,
  hasOrganizationRestaurantPermission
} from "./../../config";

import "./index.css";
import $ from "jquery";
import { strings } from "./../../localization/Login";
import { Session } from "bc-react-session";
import renderHTML from "react-render-html";

import { getFCMToken } from "../../utils/firebaseUtils";

// import "./style";
export default class Login extends Component {
  state = {
    usercode: "",
    password: "",
    orgcode: "",
    // pass_error: "",
    error: "",
    loader: "",
    rut: "",
    documentType: "33",
    ticketNumber: "",
    date: "",
    ticketTotal: "",
    pdfFormat: 0,
    forgot_password: false,
    verify_transaction: false,
    verify_transaction_url: false,
    modalIsOpen: false,
    redirect: false,
    have_code: false,
    otp: "",
    verify: false,
    errors: "",
    remember_me: false,
    button: false,
    modelData: ``,
    isEmailEntered: false,
    showLoader: false,
  };


  constructor(props) {
    console.log('props 121224244', props);
    super(props);
    strings.setLanguage(Language);
    // alert(this.props.route);
  }

  componentDidMount() {
    // Session.start({
    //   payload: "data"
    // });
    // var currentRoutes = this.context.router.getCurrentRoutes();
    // var activeRouteName = currentRoutes[currentRoutes.length - 1].name;
    const code = this.props
    console.log('test', code)
    if (document.location.pathname === "/transaction-verify") {
      this.setState({ verify_transaction_url: true });
      this.setState({ verify_transaction: true });
      var queryString = document.location.search;
      var queryParam = []
      if (queryString != '' && queryString !== undefined && queryString !== null) {
        queryParam = queryString.split('?')[1].split('&');
      }
      if (queryParam.length > 0) {
        for (var i = 0; i < queryParam.length; i++) {
          queryParam[i] = queryParam[i].split("=");
          queryParam[queryParam[i][0]] = queryParam[i][1];
        }
        console.log(queryParam);
        this.state.rut = queryParam["R"];
        this.state.documentType = queryParam["T"];
        this.state.ticketNumber = queryParam["N"];
        this.state.date = queryParam["F"];
        this.state.ticketTotal = queryParam["M"];
        this.state.pdfFormat =
          typeof queryParam["FORMAT"] == "undefined" ? 0 : queryParam["FORMAT"];
        this.searchDocument();
      }
    }

    const params = new URLSearchParams(window.location.search);

    if (params.has("o")) {
      if (params.get("o") === "verify") {
        this.setState({ verify_transaction_url: true });
        this.setState({ verify_transaction: true });
      }
    }
  }

  login() {
    this.setState({
      loader: "fa fa-spinner fa-spin",
      button: true
    });
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    let bodyData = {
      organisationCode: this.state.orgcode,
      userName: this.state.usercode,
      password: this.state.password
    };
    bodyData[HEADER_ORIGIN_KEY] = HEADER_ORIGIN;
    console.log('bodyData', bodyData);
    fetch(`${API_URL}authlogin`, {
      mode: "cors",
      method: "POST",
      credentials: "include",
      headers: headers,
      body: JSON.stringify(bodyData)
    })
      .then(res => {
        let status = "";
        if (res.status === 200) {
          fetch(`${API_URL}authlogin`, {
            method: "GET",
            // headers: {
            //   Accept: "application/json",
            //   "Content-Type": "application/json"
            // },
            //   xhrFields: {
            //     withCredentials: true
            //  },
            credentials: "include"
          })
            .then(res => {
              status = res.status;
              return res.json();
            })
            .then(async res => {
              let expire = "";

              if (status === 200) {
                if (this.state.remember_me) {
                  expire = new Date(Date.now() + 86400000 * 999);
                } else {
                  expire = new Date(Date.now() + 86400000);
                }
                const data = JSON.stringify(res);
                await localStorage.setItem("user_info", data);
                let user_info = await localStorage.getItem('user_info');
                let result = JSON.parse(user_info);
                fetch(`${API_URL}organisations/${result.data.organisationID}`, {
                  method: 'get',
                  credentials: 'include'
                })
                  .then((res) => res.json())
                  .then(async (res) => {
                    res = JSON.stringify(res);
                    await localStorage.setItem('organisation_info', res);
                  })
                fetch(`${API_URL}settings`, {
                  method: 'get',
                  credentials: 'include'
                })
                  .then((res) => res.json())
                  .then(async (res) => {
                    res = JSON.stringify(res);
                    await localStorage.setItem('settings_info', res);

                  })
                let userId = res._id;
                Session.start({
                  payload: data,
                  expiration: expire // (optional) defaults to 1 day
                });

                fetch(`${API_URL}permissions?userid=${userId}`, {
                  method: "GET",
                  credentials: "include"
                })
                  .then(res => res.json())
                  .then(async res => {
                    // TOOD: NOTIFICATION CODE
                    let hasOrganizationRestaurantModulePermission = hasOrganizationRestaurantPermission();
                    console.log('hasOrganizationRestaurantModulePermission', hasOrganizationRestaurantModulePermission);
                    const permission = await Notification.requestPermission();
                    if (hasOrganizationRestaurantModulePermission && permission === "granted") {
                      const fcmToken = await getFCMToken();
                      console.log('fcmToken : ', fcmToken)
                      // Deivce register API start
                      let statusCode = 0;
                      const deviceUuid = crypto.randomUUID()
                      const requestData = {
                        deviceToken: fcmToken,
                        deviceName: "web",
                        userID: userId,
                        deviceUUID: deviceUuid,
                        deviceType: 'web'
                      }
                      console.log('request data for register-device', requestData);
                      fetch(`${API_URL}register-device`, {
                        method: 'post',
                        headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/json',
                        },
                        credentials: 'include',
                        body: JSON.stringify(requestData)
                      }).then((res) => {
                        console.log('statuscode of register device', res.status);
                        statusCode = res.status;
                        return res.json();
                      })
                        .then(async (response) => {
                          console.log('response of register device', response);
                          if (statusCode === 200) {
                            res = res.data.permissions;
                            res = JSON.stringify(res);
                            localStorage.setItem("user_permissions", res);
                            window.location.replace("/dashboard");
                          } else {
                            this.setState({
                              loading: false
                            })
                          }
                        })
                        .catch((err) => {
                          console.log('catch block error at register device api', err);
                          this.setState({
                            loading: false
                          })
                        })
                      // Deivce register API end
                    } else {
                      res = res.data.permissions;
                      res = JSON.stringify(res);
                      localStorage.setItem("user_permissions", res);
                      window.location.replace("/dashboard");
                    }
                  })
                  .catch(err => {
                    console.log(err);
                    this.setState({
                      loader: "",
                      button: false
                    });
                    this.setState({ loading: false });
                  });
              } else {
                alert(res.message);
                this.setState({
                  loader: "",
                  button: false
                });
              }
            })
            .catch(err => {
              console.log(err);
              this.setState({
                loader: "",
                button: false
              });
              this.setState({ loading: false });
            });
        }
        return res.json();
      })
      .then(res => {
        if (res.message) {
          this.setState({
            errors: res.message,
            button: false,
            loader: ""
          });
        }
      })
      .catch(err => {
        alert(err);
        this.setState({
          loader: "",
          button: false
        });
      });
  }

  handleClick = e => {
    e.preventDefault();

    if (
      this.state.usercode === "" ||
      this.state.orgcode === "" ||
      this.state.password === ""
    ) {
      if (this.state.usercode === "") {
        this.setState({
          errors: strings.required_usercode
        });
      } else if (this.state.orgcode === "") {
        this.setState({
          errors: strings.required_orgcode
        });
      } else if (this.state.password === "") {
        this.setState({
          errors: strings.required_password
        });
      }
    } else if (this.state.usercode !== "" && this.state.password !== "") {
      this.setState({
        errors: ""
      });

      // let re =/^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/';

      /*if (!re.test(this.state.usercode)) {
        this.setState({
          user_error: "Email is not valid",
        });
      } else*/ {
        this.login();
      }
    }
  };
  handleChange = otp => this.setState({ otp });

  verifyOTP() {
    let otp = this.state.otp;
    if (otp === "1111") {
      this.setState({
        verify: true,
        have_code: false,
        errors: false
      });
    } else {
      this.setState({
        errors: strings.invalid_code
      });
    }
  }

  sendOTP() {
    let email = this.state.usercode;
    if (email === "") {
      this.setState({
        errors: strings.required_usercode
      });
    }
    // else if (this.state.orgcode === "") {
    //   this.setState({
    //     errors: strings.required_orgcode
    //   });
    // } 
    else {
      this.setState({
        success: strings.send_success + " your email",
        errors: "",
        usercode: "",
        // orgcode: ""
      });
    }
  }

  ForgotPasswordSendEmail() {
    let email = this.state.usercode;
    this.setState({ showLoader: true });
    if (email === "") {
      this.setState({
        errors: strings.required_usercode,
        showLoader: false
      });
    }
    else {
      this.setState({
        // success: strings.send_success + " your email",
        errors: "",
        usercode: "",
        isEmailEntered: true,
        showLoader: false,
        // orgcode: ""
      });
    }
  }

  changePassword() {
    const newPassword = $("#n_password").val();
    const confirmPassword = $("#c_password").val();

    if (newPassword === "" || confirmPassword === "") {
      this.setState({
        errors: strings.fill_fields
      });
    } else {
      if (newPassword !== confirmPassword) {
        this.setState({
          errors: strings.passwords_not_match
        });
      } else {
        // setButton(true);
        // setLoader("fa fa-spinner fa-spin");

        this.setState({
          button: true,
          loader: "fa fa-spinner fa-spin"
        });

        setTimeout(() => {
          this.setState({
            button: false,
            loader: "",
            success: strings.password_success,
            errors: "",
            forgot_password: false
          });

          $("#n_password").val("");
          $("#c_password").val("");
        }, 1000);
      }
    }
  }

  documentType(e) {
    this.setState({ documentType: parseInt(e.target.value) });
  }

  searchDocument() {
    if (this.state.rut === "") {
      this.setState({
        errors: strings.fill_fields
      });
    } else if (this.state.ticketNumber === "") {
      this.setState({
        errors: strings.fill_fields
      });
    } else if (this.state.ticketTotal === "") {
      this.setState({
        errors: strings.fill_fields
      });
    } else if (this.state.date === "") {
      this.setState({
        errors: strings.fill_fields
      });
    } else {
      // alert("ok");
      // this.setState({
      //   errors: ""
      // });
      this.setState({
        loader: "fa fa-spinner fa-spin",
        errors: "",
        success: "",
        button: true
      });

      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json"
      };

      let bodyData = {
        orgRutNumber: this.state.rut,
        documentType: this.state.documentType,
        ticketNumber: this.state.ticketNumber,
        ticketIssueDate: this.state.date,
        ticketTotal: this.state.ticketTotal,
        format: parseInt(this.state.pdfFormat)
      };

      let status = "";

      fetch(`${API_URL}verifyTransaction`, {
        mode: "cors",
        method: "POST",
        credentials: "include",
        headers: headers,
        body: JSON.stringify(bodyData)
      })
        .then(res => {
          status = res.status;
          return res.json();
        })
        .then(res => {
          if (status === 200) {
            this.setState({
              success: res.message,
              button: false,
              loader: "",
              modalIsOpen: true,
              modelData: res.html
            });
          } else {
            this.setState({
              errors: res.message,
              button: false,
              loader: ""
            });
          }
        })
        .catch(e => {
          this.setState({
            button: false,
            loader: ""
          });
        });
    }
  }

  render() {
    return (
      <>
        {this.state.modalIsOpen ? (
          <>
            <button
              type="button"
              className="btn btn-danger btn-block mt-4 btn-cancel"
              style={{
                position: "absolute",
                width: "100px",
                right: 100,
                zIndex: 99
                // marginLeft: "auto",
                // marginRight: "auto"
              }}
              onClick={() => this.setState({ modalIsOpen: false })}
            >
              Cerrar
            </button>
            {renderHTML(this.state.modelData)}
          </>
        ) : (
          <div className="auth-wrapper">
            <div className="auth-inner">
              {this.state.forgot_password ? (
                <Row>
                  <Col lg="12">
                    <form>
                      <img
                        src={Logo}
                        alt=""
                        height="80"
                        id="main_page_logo"
                        style={{
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "50%"
                        }}
                      />

                      {this.state.errors && (
                        <div
                          className="alert alert-danger alert-dismissible fade show mt-2"
                          role="alert"
                        >
                          {this.state.errors}
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                            onClick={() => this.setState({ errors: "" })}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      )}
                      {this.state.success && (
                        <div
                          className="alert alert-success alert-dismissible fade show"
                          role="alert"
                        >
                          {this.state.success}
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                            onClick={() => this.setState({ success: "" })}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      )}

                      {/* <h3>Forgot Password</h3> */}

                      {this.state.have_code ? (
                        <>
                          <h5 className="text-center py-3">
                            {strings.enter_code}
                          </h5>

                          <div className="mt-2" style={{ marginLeft: "3rem" }}>
                            <OtpInput
                              value={this.state.otp}
                              onChange={this.handleChange}
                              numInputs={4}
                              separator={
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                              }
                              inputStyle={{
                                height: "3rem",
                                width: "3rem",
                                fontWeight: "bold"
                              }}
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-primary btn-block mt-4"
                            onClick={e => this.verifyOTP(e)}
                          >
                            <i
                              className={"fa fa-spinner fa-spin"}
                              // className={this.state.loader}
                              style={{ fontSize: "15px" }}
                            ></i>
                            &nbsp;{strings.verify_code}
                          </button>
                          <p className="forgot-password text-right">
                            <a
                              href="javascrit:;"
                              className="link_class"
                              onClick={() =>
                                this.setState({
                                  forgot_password: false,
                                  have_code: false,
                                  success: "",
                                  errors: "",
                                  otp: ""
                                })
                              }
                            >
                              {strings.back_login}
                            </a>
                          </p>
                        </>
                      ) : this.state.verify ? (
                        // <h1>new password</h1>
                        <>
                          {/* <span className="text-danger font-weight-normal alert alert-danger">
                        </span> */}
                          <PasswordField
                            title={strings.new_password}
                            name="n_password"
                            id="n_password"
                          />
                          <PasswordField
                            title={strings.confirm_password}
                            name="c_password"
                            id="c_password"
                          />

                          <button
                            type="button"
                            className="btn btn-primary btn-block"
                            onClick={() => this.changePassword()}
                          >
                            <i
                              className={this.state.loader}
                              style={{ fontSize: "15px" }}
                            ></i>
                            &nbsp;Change Password
                          </button>
                          <p className="forgot-password text-right">
                            <a
                              href="javascrit:;"
                              className="link_class"
                              onClick={() =>
                                this.setState({
                                  forgot_password: false,
                                  have_code: false,
                                  verify: false,
                                  otp: false
                                })
                              }
                            >
                              {strings.back_login}
                            </a>
                          </p>
                        </>
                      ) : (
                        /* todo */
                        <div>
                          {/* {this.state.isEmailEntered
                          ? (
                            <>
                              <SuccessMessage 
                                message = {strings.email_send_successfully}
                              />
                            </>
                          ) 
                          : (
                            <>
                          <div className="form-group">
                            <h4 className="text-black mt-2 text-center fw-bold">{strings.forgot_old_passowrd}</h4>
                            <p className="font-size-5 mt-4 ">{strings.email_instruction}</p>

                            {this.state.errors &&
                                 <div
                                  className="alert alert-danger alert-dismissible fade show mt-2"
                                  role="alert"
                                >
                                  {this.state.errors}
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                    onClick={() => this.setState({ errors: "" })}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                            }
                            <InputGroup seamless className="mb-3 mt-3">
                              <input
                                type="text"
                                className="form-control textBox"
                                placeholder="Email"
                                value={this.state.usercode}
                                onChange={e =>
                                  this.setState({ usercode: e.target.value })
                                }
                              />
                            </InputGroup>
                          </div>

                         <div className="d-flex justify-content-center align-items-center">
                          <button
                            type="button"
                            className="btn btn-primary btn-block"
                            onClick={e => this.ForgotPasswordSendEmail(e)}
                            style= {{
                              letterSpacing:'1px',
                              height: '40px',
                              width: '130px',
                              display: 'flex',
                              justifyContent:'center',
                              alignItems:'center',
                            }}
                          >
                            {this.state.showLoader &&  <span className="spinner-border spinner-border-sm mx-1" role="status"  aria-hidden="true"></span>}
                            &nbsp;&nbsp;{strings.send_email_button}
                          </button>
                          </div>

                            <p className="forgot-password text-right">
                              <a
                                href="javascrit:;"
                                className="link_class fst-italic text-black fw-bold"
                                onClick={() =>
                                  this.setState({ forgot_password: false })
                                }
                              >
                                {strings.cancel}
                              </a>
                            </p>
                            </>
                          )} */}

                        </div>
                      )}
                    </form>
                  </Col>
                </Row>
              ) : (
                <>
                  <Row>
                    <Col lg="12">
                      <form>
                        <img
                          src={Logo}
                          alt=""
                          height="80"
                          id="main_page_logo"
                          style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "50%"
                          }}
                        />
                        {this.state.errors && (
                          <div
                            className="alert alert-danger alert-dismissible fade show mt-2"
                            role="alert"
                          >
                            {this.state.errors}
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-label="Close"
                              onClick={() => this.setState({ errors: "" })}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        )}
                        {this.state.success && (
                          <div
                            className="alert alert-success alert-dismissible fade show"
                            role="alert"
                          >
                            {this.state.success}
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-label="Close"
                              onClick={() => this.setState({ success: "" })}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        )}

                        {this.state.verify_transaction ? (
                          <>
                            <div className="form-group mt-2">
                              <label>RUT de la tienda donde compraste </label>
                              <InputGroup seamless className="mb-3">
                                <InputGroupAddon type="prepend">
                                  <InputGroupText>
                                    <i className="material-icons">business</i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <input
                                  type="text"
                                  className="form-control textBox"
                                  placeholder="Ejemplo: 11111111-1"
                                  value={this.state.rut}
                                  onChange={e =>
                                    this.setState({ rut: e.target.value })
                                  }
                                  onBlur={() => {
                                    let value = updateNumber(this.state.rut);
                                    this.setState({
                                      rut: value
                                    });
                                  }}
                                />
                              </InputGroup>
                            </div>
                            <div className="form-group">
                              <label>Tipo de Documento </label>
                              <InputGroup seamless className="mb-3">
                                <InputGroupAddon type="prepend">
                                  <InputGroupText>
                                    <i className="material-icons">business</i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <select
                                  className="form-control"
                                  value={this.state.documentType}
                                  onChange={e => this.documentType(e)}
                                >
                                  <option value="33">
                                    FACTURA ELECTRONICA (COD. 33)
                                  </option>
                                  <option value="34">
                                    FACTURA NO AFECTA O EXENTA ELECTRONICA (COD.
                                    34)
                                  </option>
                                  <option value="39">
                                    BOLETA ELECTRONICA (COD. 39)
                                  </option>
                                  <option value="41">
                                    BOLETA EXENTA ELECTRONICA (COD. 41)
                                  </option>
                                  <option value="56">
                                    NOTA DEBITO ELECTRONICA (COD. 56)
                                  </option>
                                  <option value="61">
                                    NOTA CREDITO ELECTRONICA (COD. 61)
                                  </option>
                                  <option value="52">
                                    GUIA DE DESPACHO (COD. 52)
                                  </option>
                                </select>
                              </InputGroup>
                            </div>
                            <div className="form-group">
                              <label>Número de Documento </label>
                              <InputGroup seamless className="mb-3">
                                <InputGroupAddon type="prepend">
                                  <InputGroupText>
                                    <i className="material-icons">business</i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <input
                                  type="text"
                                  className="form-control textBox"
                                  placeholder="Ejemplo: 123456"
                                  value={this.state.ticketNumber}
                                  onChange={e =>
                                    this.setState({
                                      ticketNumber: e.target.value
                                    })
                                  }
                                />
                              </InputGroup>
                            </div>
                            <div className="form-group">
                              <label>Fecha de emisión </label>
                              <InputGroup seamless className="mb-3">
                                <InputGroupAddon type="prepend">
                                  <InputGroupText>
                                    <i className="material-icons">business</i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <input
                                  type="date"
                                  className="form-control textBox"
                                  placeholder="Date of Issuance of the Electronic Ballot "
                                  value={this.state.date}
                                  onChange={e =>
                                    this.setState({ date: e.target.value })
                                  }
                                />
                              </InputGroup>
                            </div>
                            <div className="form-group">
                              <label>Total de la compra </label>
                              <InputGroup seamless className="mb-3">
                                <InputGroupAddon type="prepend">
                                  <InputGroupText>
                                    <i className="material-icons">business</i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <input
                                  type="number"
                                  className="form-control textBox"
                                  placeholder="Ejemplo: 10990"
                                  value={this.state.ticketTotal}
                                  onChange={e =>
                                    this.setState({
                                      ticketTotal: e.target.value
                                    })
                                  }
                                />
                              </InputGroup>
                            </div>
                            <button
                              type="button"
                              className="btn btn-primary btn-block"
                              onClick={e => this.searchDocument(e)}
                              disabled={this.state.button}
                            >
                              <i
                                className={this.state.loader}
                                style={{ fontSize: "15px" }}
                              ></i>
                              &nbsp;&nbsp;Buscar Documento
                            </button>

                            <p
                              className="forgot-password text-right"
                              style={{
                                display: this.state.verify_transaction_url
                                  ? "none"
                                  : "block"
                              }}
                            >
                              <a
                                href="javascrit:;"
                                className="link_class"
                                onClick={() =>
                                  this.setState({
                                    verify_transaction: false,
                                    success: "",
                                    errors: "",
                                    otp: ""
                                  })
                                }
                              >
                                {strings.back_login}
                              </a>
                            </p>
                          </>
                        ) : (
                          <>
                            <div className="form-group">
                              <label>RUT de Empresa</label>
                              <InputGroup seamless className="mb-3">
                                <InputGroupAddon type="prepend">
                                  <InputGroupText>
                                    <i className="material-icons">business</i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <input
                                  type="text"
                                  className="form-control textBox"
                                  placeholder="RUT de Empresa"
                                  value={this.state.orgcode}
                                  onChange={e =>
                                    this.setState({ orgcode: e.target.value })
                                  }
                                  onBlur={() => {
                                    let value = updateNumber(
                                      this.state.orgcode
                                    );
                                    this.setState({
                                      orgcode: value
                                    });
                                  }}
                                />
                              </InputGroup>
                            </div>

                            <div className="form-group">
                              <label>RUT de usuario</label>
                              <InputGroup seamless className="mb-3">
                                <InputGroupAddon type="prepend">
                                  <InputGroupText>
                                    <i className="material-icons">code</i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <input
                                  type="text"
                                  className="form-control textBox"
                                  placeholder="RUT de usuario"
                                  value={this.state.usercode}
                                  onChange={e =>
                                    this.setState({ usercode: e.target.value })
                                  }
                                  onBlur={() => {
                                    let value = updateNumber(
                                      this.state.usercode
                                    );
                                    this.setState({
                                      usercode: value
                                    });
                                  }}
                                />
                              </InputGroup>
                            </div>

                            <div className="form-group">
                              <label>Clave</label>
                              <InputGroup seamless className="mb-3">
                                <InputGroupAddon type="prepend">
                                  <InputGroupText>
                                    <i className="material-icons">vpn_key</i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Clave"
                                  value={this.state.password}
                                  onChange={e =>
                                    this.setState({ password: e.target.value })
                                  }
                                />
                              </InputGroup>

                              {/* <span className="text-danger font-weight-normal">
                          {this.state.pass_error}
                        </span> */}
                            </div>

                            <div className="form-group">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck1"
                                  onClick={() =>
                                    this.setState({
                                      remember_me: !this.state.remember_me
                                    })
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck1"
                                >
                                  Recuérdame
                                </label>
                              </div>
                            </div>

                            <button
                              type="button"
                              className="btn btn-primary btn-block"
                              onClick={e => this.handleClick(e)}
                              disabled={this.state.button}
                            >
                              <i
                                className={this.state.loader}
                                style={{ fontSize: "15px" }}
                              ></i>
                              &nbsp;&nbsp;Ingresar
                            </button>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between"
                              }}
                            >
                              <p className="forgot-password">
                                <a
                                  href="javascrit:;"
                                  className="link_class"
                                  onClick={() =>
                                    this.setState({
                                      verify_transaction: true,
                                      errors: ""
                                    })
                                  }
                                >
                                  Verifica tu documento
                                </a>
                              </p>
                              <p className="forgot-password">
                                <Link
                                  href="javascrit:;"
                                  className="link_class"
                                  to={'/forget-password'}
                                  onClick={() =>
                                    this.setState({
                                      forgot_password: true,
                                      errors: ""
                                    })
                                    // history.push('/forgot-password')
                                    // window.location.push("/forgot-password")
                                  }
                                >
                                  ¿Has olvidado tu contraseña?
                                </Link>
                                {/* <a
                                  href="javascrit:;"
                                  className="link_class"
                                  onClick={() =>
                                    this.setState({
                                      forgot_password: true,
                                      errors: ""
                                    })
                                    // history.push('/forgot-password')
                                      // window.location.push("/forgot-password")
                                  }
                                >
                                  ¿Has olvidado tu contraseña?
                                </a> */}
                              </p>
                            </div>
                          </>
                        )}
                      </form>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}
